import { get } from "jquery";
import GET_ARTICLES from "../class/getArticles";

const [w,d] = [window,document];

class Blocks {
  constructor(){
    this.init();
  }
  init () {
    this.news();
    this.works();
  }
  mainvisual() {
    let obj = d.querySelector('.mainvisual');
    obj.classList.add('is-ready');
  }

  news () {
    let getArticles = new GET_ARTICLES('.block-news__list[data-plugin="getArticles"]');
    getArticles.makeItem = (item,content) => `
      <li class="block-${content}__item${(item.is_new)? ' is-new':''}">
        <a href="${item.href}" class="block-${content}__item-link">
          <div class="block-${content}__item-head">
            <span class="date">${item.date}</span>
            <span class="cat">${item.category.name}</span>
          </div>
          <!-- /.block-${content}__item-head -->
          <span class="subject">${item.title}</span>
        </a>
        <!-- /.block-${content}__item-link -->
      </li>
      <!-- /.block-${content}__item -->`;
    getArticles.render();

    
    const getContentData = Promise.all([
      getArticles.fetchArticles({
        content: 'news',
        post_per_page: 1,
        category_in: 0
      }),
      getArticles.fetchCategories('news'),
    ]).then( res => Promise.all(res.map(r=>r.json())) );

    let categoryContainer = d.querySelector('.block-news__catlist[data-plugin="getCategories"]');
    getContentData.then( ([noCatArticles,cats]) => {
      categoryContainer.innerHTML = '';
      if( noCatArticles && noCatArticles.length > 0){
        cats.push({id:'0',name:'その他',href:`/news/?mode=list&cat=0`});
      }
      cats.forEach( item => {
        let itemHtml = `<li class="block-news__catitem"><a href="${item.href}">${item.name}</a></li>`;
        categoryContainer.insertAdjacentHTML('beforeend', itemHtml);
      });
    });
  }

  works () {
    let getArticles = new GET_ARTICLES('.block-works__list[data-plugin="getArticles"]');
    getArticles.makeItem = (item,content) => `
      <li class="block-${content}__item">
        <a href="${item.href}" class="block-${content}__item-link">
          <div class="block-${content}__item-img">
            <img src="${item.thumb}" alt="${item.title}">
            ${(item.category)? `<span class="cat">${item.category.name}</span>`: ''}
          </div>
          <!-- /.block-${content}__item-img -->
          <div class="block-${content}__item-body">
            <span class="subject">${item.title}</span>
          </div>
          <!-- /.block-${content}__item-body -->
        </a>
        <!-- /.block-${content}__item-link -->
      </li>
      <!-- /.block-${content}__item -->`;
    getArticles.render();

  }
}

const domReady = TPL => {
  let blocks = new Blocks;
  w.addEventListener('load', () => blocks.mainvisual() );
};

const jqInit = () => false;

export default function HOME () {
  // テンプレートが用意したDOMContentLoaded（テンプレートインスタンスが引数に入る）
  d.addEventListener('rwd002.beforeDomready', e => domReady(e.detail) );
  
  $(() => jqInit());
}